import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";
import arrow_down from "../../../images/icons/arrow_down.svg";
import checked_icon from "../../../images/icons/checked_icon.svg";

import useOutsideClick from "../../../hooks/useOutsideClick";
import "./CommonSelect.css";
import { isArray } from "lodash";

function CommonSelect({
  name,
  value,
  onChange,
  onBlur,
  error,
  touched,
  options,
  errorMsg,
  isHoverEffect,
  styleText = {},
  stylesContainer = {},
  dropdownStyle = {},
  styleDropdownlist = {},
  spanStyle = {},
  disabled,
  addPercent,
  isNotFull = false,
  isMultiSelect,
  showLegend = true,
  hideScrollBar,
  showOnlyDropDown = false,
}) {
  const optionsRef = React.useRef(null);
  const [showOption, setShowOption] = useState(false);
  const isClickedOutside = useOutsideClick(optionsRef);
  const myRef = useRef(null);

  useEffect(() => {
    if (isClickedOutside) {
      setShowOption(false);
      if (onBlur) {
        onBlur();
      }
    }
  }, [isClickedOutside]);

  const containerClasses = classnames(
    `w-full bg-white py-auto text-base text-black-600 font-roboto font-normal relative rounded hover:border`,
    {
      "border border-grey-2440": !showLegend,
      "border border-red-300": error && touched,
      "border border-green-600": !error && touched && showLegend,
      "border border-grey-1009": !touched && showLegend,
      "border border-grey-2440": touched && !showLegend && !error,
    },
    {
      "hover:border-green-600 ": !isHoverEffect,
    },
    {
      "hover:border-green-600 focus:border-blue-700 focus:border-2": isHoverEffect && showLegend,
      "hover:border-1 hover:border-green-2100 focus:border-green-2100 focus:border-2": !showLegend && isHoverEffect,
    },
    {
      "cursor-not-allowed ": disabled,
    }
  );

  return (
    <div
      tabIndex="0"
      className={containerClasses}
      ref={myRef}
      // onClick={focusHandler}
      style={stylesContainer}
    >
      {!showOnlyDropDown && (
        <div
          onClick={e => {
            if (!disabled) setShowOption(!showOption);
          }}
          className={
            (disabled ? "cursor-not-allowed " : "cursor-pointer ") +
            ` ${
              addPercent
                ? "flex justify-end space-x-2 items-center h-full"
                : "selected_month"
            }  rounded text-xs w-full`
          }
          style={styleText}
        >
          {isNotFull ? (
            <span className="" style={spanStyle}>
              {value
                ? value?.label.length > 26
                  ? value?.label.substr(0, 26) + "..."
                  : value?.label
                : "Select"}{" "}
            </span>
          ) : (
            <span className="" style={spanStyle}>
              {isMultiSelect ? (
                isArray(value) ? (
                  value?.length > 0 ? (
                    <div className="flex flex-row">
                      {value?.map(each => (
                        <div className="px-2 mx-1 py-1 bg-grey-100 rounded-full">
                          {each?.label}
                        </div>
                      ))}
                    </div>
                  ) : (
                    "Select"
                  )
                ) : (
                  "Select"
                )
              ) : value ? (
                value.label
              ) : (
                "Select"
              )}
            </span>
          )}
          {addPercent && <span>%</span>}
          <span>
            <img
              src={arrow_down}
              className="arrow_icon text-green-600"
              alt=""
            />
          </span>
        </div>
      )}
      {(showOption || showOnlyDropDown) && (
        <div
          className={`all_dropdown w-full absolute text-sm ${
            hideScrollBar ? "scrollBar" : ""
          }`}
          ref={optionsRef}
          style={{
            top: "40px",
            zIndex: 11,
            left: 0,
            ...dropdownStyle,
          }}
        >
          {options &&
            options.map((each, i) => {
              return (
                <div
                  key={i}
                  className={` ${
                    each.disable ? "opacity-50" : "cursor-pointer"
                  }`}
                  name={name}
                  onClick={e => {
                    if (!each.disable) {
                      onChange(each); //onchangeDate
                      setShowOption(false);
                    }
                  }}
                  style={styleDropdownlist}
                >
                  <span key={i}>
                    {each.label}
                    {addPercent && "%"}
                  </span>
                  {!isMultiSelect
                    ? value?.value === each?.value && (
                        <span>
                          <img
                            className="checked_icon"
                            src={checked_icon}
                            alt=""
                          />
                        </span>
                      )
                    : value?.map(
                        ele =>
                          ele?.value === each?.value && (
                            <span>
                              <img
                                className="checked_icon"
                                src={checked_icon}
                                alt=""
                              />
                            </span>
                          )
                      )}
                </div>
              );
            })}
        </div>
      )}
      {error && errorMsg && (
        <div className="mt-1 text-xs text-red-300 ">{errorMsg}</div>
      )}
    </div>
  );
}

export default CommonSelect;
