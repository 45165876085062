import React from "react";

export default function useOutsideClick(ref, initialChange) {
  const [isClickedOutside, setClickedOutside] = React.useState(initialChange);

  function onMouseDown(event) {
    if (ref.current && !ref?.current.contains(event.target)) {
      setClickedOutside(true);
      return;
    }
    setClickedOutside(false);
  }

  React.useEffect(() => {
    document.addEventListener("mousedown", onMouseDown);
    return () => document.removeEventListener("mousedown", onMouseDown);
  });

  return isClickedOutside;
}
